import { Component, TemplateRef, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { Router } from '@angular/router';
import { AuthService } from '../../../servicios/auth.service';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import swal from 'sweetalert2'
import { LoginService } from '../../../servicios/login.service';
import { FormLayoutComponent } from '../../../forms/form-layouts/form-layout.component';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent {

  modalRef: BsModalRef;
  forma:FormGroup;
  ResultChangePw: result;

  constructor(private router: Router, private auth: AuthService, private modalService: BsModalService, private pwService: LoginService){
    this.forma = new FormGroup({
      'FPwActual': new FormControl('',Validators.required),
      'FPwNueva': new FormControl(),
      'FPwNuevaConfirmar': new FormControl()
    });

    this.forma.controls['FPwNuevaConfirmar'].setValidators([
      Validators.required, this.noIgual
    ]);
  }


    public config: PerfectScrollbarConfigInterface = {};
    // This is for Notifications
  /* public notifications: Object[] = [{
      round: 'round-danger',
      icon: 'ti-link',
      title: 'Luanch Admin',    
      subject: 'Just see the my new admin!',
      time: '9:30 AM'  
    }, {
      round: 'round-success',
      icon: 'ti-calendar',
      title: 'Event today',    
      subject: 'Just a reminder that you have event',
      time: '9:10 AM'
    }, {
      round: 'round-info', 
      icon: 'ti-settings',
      title: 'Settings',    
      subject: 'You can customize this template as you want',
      time: '9:08 AM'
    }, {
      round: 'round-primary',
      icon: 'ti-user',
      title: 'Pavan kumar',    
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }];
    
    // This is for Mymessages
   public mymessages: Object[] = [{
      useravatar: 'assets/images/users/1.jpg',
      status: 'online',
      from: 'Pavan kumar',    
      subject: 'Just see the my admin!',
      time: '9:30 AM'  
    }, {
      useravatar: 'assets/images/users/2.jpg',
      status: 'busy',
      from: 'Sonu Nigam',    
      subject: 'I have sung a song! See you at',
      time: '9:10 AM'
    }, {
      useravatar: 'assets/images/users/2.jpg',
      status: 'away',
      from: 'Arijit Sinh',    
      subject: 'I am a singer!',
      time: '9:08 AM'
    }, {
      useravatar: 'assets/images/users/4.jpg',
      status: 'offline',
      from: 'Pavan kumar',    
      subject: 'Just see the my admin!',
      time: '9:00 AM'
    }];*/


    Logout(){
     this.auth.LogoutRedirect();
     };

     GoToDatos(){
      this.router.navigate(['/MisDatos']);
     };

     ChangePassword(template: TemplateRef<any>) {
      this.modalRef = this.modalService.show(template,{class: 'modal-dialog-centered'});
    };

    guardarCambios(){
      if (this.forma.valid){
  
        let Usuario = this.auth.getUsuarioLogeado();
        
        this.pwService.changePw
        (
        Usuario.Id,
        this.forma.value['FPwActual'],
        this.forma.value['FPwNueva']
        )
        .subscribe((data:any) =>{
          this.ResultChangePw = data;
          if(this.ResultChangePw.success == false){
            swal({
              title: 'Clave errónea',
              text: 'Clave actual incorrecta',
              type: 'error',
              confirmButtonText: 'Confirmar'
            });
          }else{
            swal({
              title: '¡Cambio Exitoso!',
              text: 'Su clave ha sido cambiada exitosamente, favor iniciar sesión nuevamente',
              type: 'success',
              confirmButtonText: 'Confirmar'
            });
            this.forma.reset();
            this.modalRef.hide();
            this.router.navigate(['/authentication/login'])
          }
        });
      }else
      {
        swal({
          title: 'Acción indebida',
          text: 'Tu acción será comunicada al departamento de TI',
          type: 'error',
          confirmButtonText: 'Aceptar'
        });
      };
      
    };

    noIgual = (control:FormControl) => {
      if(control.value !== this.forma.controls["FPwNueva"].value) {
        return {
          differentpass: true
        }
      }
      return null;
    }
}


export interface result{
  success: boolean,
  msg: string;
};