import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LoginService } from '../servicios/login.service';
import { AuthService } from '../servicios/auth.service';

const helper = new JwtHelperService();

//const decodedToken = helper.decodeToken(token);
//const expirationDate = helper.getTokenExpirationDate(token);
//const isExpired = helper.isTokenExpired(token);

@Injectable({
  providedIn: 'root'
})
export class AuthHomeGuard implements CanActivate {
  constructor(private router: Router, private login: LoginService, public auth: AuthService){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        //console.log( helper.getTokenExpirationDate(localStorage.getItem('userToken')));
        // devuelve true si está autenticado
      if(this.auth.isAuthenticated())
      return true;
      this.router.navigate(['/authentication/login']);
      return false;
  }
}
 
