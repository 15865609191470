import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../servicios/auth.service';
import swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private router: Router, private auth: AuthService){

  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      let Usuario = this.auth.getUsuarioLogeado();
      if(Usuario.CodCliente === 6240)
      return true;
      swal({
        title: '¡No Autorizado!',
        text: 'Usted no se encuentra autorizado para visitar esta página',
        type: 'error',
        confirmButtonText: 'Aceptar'
      });
      this.router.navigate(['/authentication/login']);
      return false;
  }
}
