import { Routes } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { AuthGuard } from './auth/auth.guard';
import { AdminGuard } from './auth/admin.guard'
import { AuthHomeGuard } from './auth/auth-home.guard'


export const AppRoutes: Routes = [
  {path: '',  component: FullComponent,
    children: [{ path: '', redirectTo: '/Home', pathMatch: 'full' },
  {
    path: 'dashboards',
    loadChildren: './dashboards/dashboards.module#DashboardsModule'
  }, {
    path: 'material', canActivate:[AuthGuard],
    loadChildren: './material-component/material.module#MaterialComponentsModule'
  }, {
    path: 'apps', canActivate:[AuthGuard],
    loadChildren: './apps/apps.module#AppsModule'
  }, {
    path: 'forms', canActivate:[AuthGuard],
    loadChildren: './forms/forms.module#FormModule'
  }, {
    path: 'tables', canActivate:[AuthGuard],
    loadChildren: './tables/tables.module#TablesModule'
  }, {
    path: 'datatables', canActivate:[AuthGuard],
    loadChildren: './datatables/datatables.module#DataTablesModule'
  }, {
    path: 'pages', canActivate:[AuthGuard],
    loadChildren: './pages/pages.module#PagesModule'
  },{
    path: 'widgets', canActivate:[AuthGuard],
    loadChildren: './widgets/widgets.module#WidgetsModule'
  }, {
    path: 'charts', canActivate:[AuthGuard],
    loadChildren: './charts/chartslib.module#ChartslibModule'
  },
  {
    path: 'MisTarjetas', canActivate:[AuthGuard],
    loadChildren: './mis-tarjetas/mis-tarjetas.module#MisTarjetasModule'
  },
  {
    path: 'SolicitudesDeTarjetas', canActivate:[AuthGuard],
    loadChildren: './solicitudes-de-tarjetas/solicitudes-de-tarjetas.module#SolicitudesDeTarjetasModule'
  },
  {
    path: 'SolicitudesComerciales', canActivate:[AuthGuard],
    loadChildren: './solicitudes-comerciales/solicitudes-comerciales.module#SolicitudesComercialesModule'
  },
  {
    path: 'CecosRut', canActivate:[AuthGuard],
    loadChildren: './cecos-rut/cecos-rut.module#CecosRutModule'
  },
  {
    path: 'MisDatos', canActivate:[AuthGuard],
    loadChildren: './mis-datos/mis-datos.module#MisDatosModule'
  },
  {
    path: 'Home', canActivate:[AuthHomeGuard],
    loadChildren: './home/home.module#HomeModule'
  },
  {
    path: 'administracion', canActivate:[AuthGuard,AdminGuard],
    loadChildren: './administracion/administracion.module#AdministracionModule'
  }]
},{
  path: '',
  component: AppBlankComponent,
  children: [{
    path: 'authentication',
    loadChildren: './authentication/authentication.module#AuthenticationModule'
  }]
}, {
  path: '**',
  redirectTo: 'authentication/404'
}];


//RUTAS REALES
/*
export const AppRoutes: Routes = [{
  path: '',
  component: FullComponent,
  children: [{
    path: '',
    redirectTo: '/dashboards/dashboard1',
    pathMatch: 'full'
  },{
    path: 'dashboards',
    loadChildren: './dashboards/dashboards.module#DashboardsModule'
  }, {
    path: 'material',
    loadChildren: './material-component/material.module#MaterialComponentsModule'
  }, {
    path: 'apps',
    loadChildren: './apps/apps.module#AppsModule'
  }, {
    path: 'forms',
    loadChildren: './forms/forms.module#FormModule'
  }, {
    path: 'tables',
    loadChildren: './tables/tables.module#TablesModule'
  }, {
    path: 'datatables',
    loadChildren: './datatables/datatables.module#DataTablesModule'
  }, {
    path: 'pages',
    loadChildren: './pages/pages.module#PagesModule'
  },{
    path: 'widgets',
    loadChildren: './widgets/widgets.module#WidgetsModule'
  }, {
    path: 'charts',
    loadChildren: './charts/chartslib.module#ChartslibModule'
  }]
},{
  path: '',
  component: AppBlankComponent,
  children: [{
    path: 'authentication',
    loadChildren: './authentication/authentication.module#AuthenticationModule'
  }]
}, {
  path: '**',
  redirectTo: 'authentication/404'
}];
*/
