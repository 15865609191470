import { Injectable } from '@angular/core';
import { AuthService } from '../../servicios/auth.service';

export interface BadgeItem {
  type: string;
  value: string;
}
export interface Saperator {
  name: string;
  type?: string;
}
export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  saperator?: Saperator[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  { state: '',
    name: 'Personal',
    type: 'saperator',
    icon: 'av_timer'
  },
  {
  state: 'MisTarjetas',
  name: 'Mis Tarjetas',
  type: 'link',
  icon: 'credit_card'
},
    {
    state: 'SolicitudesDeTarjetas',
    name: 'Solicitudes de Tarjetas',
    type: 'sub',
    icon: 'contact_mail',
    children: [
      {state: 'MisSolicitudes', name: 'Mis Solicitudes'},
      {state: 'NuevaSolicitud', name: 'Solicitud Tarjeta Unitaria'},
      {state: 'NuevaSolicitudMasiva', name: 'Solicitud Tarjeta Masiva'}
    ]
  },
  {
    state: 'SolicitudesComerciales',
    name: 'Solicitudes Comerciales',
    type: 'link',
    icon: 'work'
  },
  {
    state: 'CecosRut',
    name: 'Facturación',
    type: 'sub',
    icon: 'business',
    children: [
      {state: 'CentroCosto', name: 'Centro de Costo'},
      {state: 'RutFacturar', name: 'Rut a Facturar'}
    ]
  },
  {
    state: 'MisDatos',
    name: 'Mis Datos',
    type: 'link',
    icon: 'how_to_reg'},
  {
      state: 'administracion',
      name: 'Administración',
      type: 'link',
      icon: 'account_box'}

];

const MENUITEMS2 = [
  { state: '',
    name: 'Personal',
    type: 'saperator',
    icon: 'av_timer'
  },
  {
  state: 'MisTarjetas',
  name: 'Mis Tarjetas',
  type: 'link',
  icon: 'credit_card'
},
    {
    state: 'SolicitudesDeTarjetas',
    name: 'Solicitudes de Tarjetas',
    type: 'sub',
    icon: 'contact_mail',
    children: [
      {state: 'MisSolicitudes', name: 'Mis Solicitudes'},
      {state: 'NuevaSolicitud', name: 'Solicitud Tarjeta Unitaria'},
      {state: 'NuevaSolicitudMasiva', name: 'Solicitud Tarjeta Masiva'}
    ]
  },
  {
    state: 'SolicitudesComerciales',
    name: 'Solicitudes Comerciales',
    type: 'link',
    icon: 'work'
  },
  {
    state: 'CecosRut',
    name: 'Facturación',
    type: 'sub',
    icon: 'business',
    children: [
      {state: 'CentroCosto', name: 'Centro de Costo'},
      {state: 'RutFacturar', name: 'Rut a Facturar'}
    ]
  },
  {
    state: 'MisDatos',
    name: 'Mis Datos',
    type: 'link',
    icon: 'how_to_reg'}
];
/*const MENUITEMS = [
  { state: '',
    name: 'Personal',
    type: 'saperator',
    icon: 'av_timer'
  },{
    state: 'dashboards',
    name: 'Dashboards',
    type: 'sub',
    icon: 'av_timer',
    children: [
      {state: 'dashboard1', name: 'Dashboard 1'},
      {state: 'dashboard2', name: 'Dashboard 2'},
    ]
  },
    {
    state: 'material',
    name: 'Material Ui',
    type: 'sub',
    icon: 'bubble_chart',
    badge: [
      {type: 'red', value: '17'}
    ],
    children: [
      {state: 'button', name: 'Buttons'},
      {state: 'cards', name: 'Cards'},
      {state: 'grid', name: 'Grid List'},
      {state: 'lists', name: 'Lists'},
      {state: 'menu', name: 'Menu'},
      {state: 'tabs', name: 'Tabs'},
      {state: 'stepper', name: 'Stepper'},
      {state: 'expansion', name: 'Expansion Panel'},
      {state: 'chips', name: 'Chips'},
      {state: 'toolbar', name: 'Toolbar'},
      {state: 'progress-snipper', name: 'Progress snipper'},
      {state: 'progress', name: 'Progress Bar'},
      {state: 'dialog', name: 'Dialog'},
      {state: 'tooltip', name: 'Tooltip'},
      {state: 'snackbar', name: 'Snackbar'},
      {state: 'slider', name: 'Slider'},
      {state: 'slide-toggle', name: 'Slide Toggle'}
    ]
  },
  {
    state: 'apps',
    name: 'Apps',
    type: 'sub',
    icon: 'apps',
    children: [
      {state: 'calendar', name: 'Calendar'},
      {state: 'messages', name: 'Mail box'},
      {state: 'chat', name: 'Chat'},
      {state: 'taskboard', name: 'Taskboard'}
    ]
  },
    {
    state: '',
    name: 'Forms, Table & Widgets',
    type: 'saperator',
    icon: 'av_timer'
  },{
    state: 'forms',
    name: 'Forms',
    type: 'sub',
    icon: 'insert_drive_file',

    children: [

      {state: 'form-layout', name: 'Form-Layout'},
      {state: 'autocomplete', name: 'Autocomplete'},
      {state: 'checkbox', name: 'Checkbox'},
      {state: 'datepicker', name: 'Datepicker'},
      {state: 'editor', name: 'Editor'},
      {state: 'form-validation', name: 'Form Validation'},
      {state: 'file-upload', name: 'File Upload'},
      {state: 'wizard', name: 'Wizard'}

    ]
  },{
    state: 'tables',
    name: 'Tables',
    type: 'sub',
    icon: 'web',

    children: [

      {state: 'basictable', name: 'Basic Table'},
      {state: 'filterable', name: 'Filterable Table'},
      {state: 'pagination', name: 'Pagination Table'},
      {state: 'sortable', name: 'Sortable Table'},
      {state: 'mix', name: 'Mix Table'}

    ]
  },{
    state: 'datatables',
    name: 'Data Tables',
    type: 'sub',
    icon: 'border_all',

    children: [
      {state: 'basicdatatable', name: 'Basic Data Table'},
      {state: 'filter', name: 'Filterable'},
      {state: 'editing', name: 'Editing'},
    ]
  },{
    state: 'widgets',
    name: 'Widgets',
    type: 'link',
    icon: 'widgets'
  },{
    state: '',
    name: 'Extra Component',
    type: 'saperator',
    icon: 'av_timer'
  },
    {
    state: 'authentication',
    name: 'Authentication',
    type: 'sub',
    icon: 'perm_contact_calendar',

    children: [
      {state: 'login', name: 'Login'},
      {state: 'register', name: 'Register'},
      {state: 'forgot', name: 'Forgot'},
      {state: 'lockscreen', name: 'Lockscreen'},
      {state: '404', name: 'Error'}

    ]
  },{
    state: 'charts',
    name: 'Charts',
    type: 'sub',
    icon: 'insert_chart',

    children: [
      {state: 'chartjs', name: 'Chart Js'},
      {state: 'chartistjs', name: 'Chartist Js'},
      {state: 'ngxchart', name: 'Ngx Charts'}

    ]
  },{
    state: 'pages',
    name: 'Pages',
    type: 'sub',
    icon: 'content_copy',

    children: [
      {state: 'icons', name: 'Material Icons'},
      {state: 'timeline', name: 'Timeline'},
      {state: 'invoice', name: 'Invoice'},
      {state: 'pricing', name: 'Pricing'},
      {state: 'helper', name: 'Helper Classes'}
    ]
  }

];   */
//console.log(MENUITEMS)

// hacer un menú dinamico, por el momento preguntaré por el código de cliente y le mostraré el menu con admin.
@Injectable()

export class MenuItems {

    constructor(private auth: AuthService){

    }

  getMenuitem(): Menu[] {
    let Usuario = this.auth.getUsuarioLogeado();

    if(Usuario){
      if(Usuario.CodCliente === 6240){
        return MENUITEMS;
      }else{
        return MENUITEMS2;
      }  
    };
  }

}
