import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';



@Injectable({
  providedIn: 'root'
})
export class LoginService {

  Url: any [] = this.auth.urlApi();

  constructor(private http: HttpClient, private router: Router, private auth: AuthService) { 
    //console.log('Servicio Login activo');
  }
    getLogin(usuario,password){
     return  this.http.get(`${this.Url}/login?usuario=${usuario}&pass=${password}`);
   }

   changePw(idUsuario, oldPw ,newPw){
    let Usuario = this.auth.getUsuarioLogeado();
    return  this.http.get(`${this.Url}/changePw?pUsuario=${idUsuario}&pOldPw=${oldPw}&pNewPw=${newPw}&pContraEncript=${Usuario.Pass}`);
   }

   recoveryPassword(mail){
    return this.http.get(`${this.Url}/recoveryPassword/${mail}`)
   };

   verifyJWT(token){
    return this.http.get(`${this.Url}/verifyJWT/${token}`)
   }

   restorePassword(usuario,password){
    return  this.http.get(`${this.Url}/restorePw?pUsuario=${usuario}&pPass=${password}`);
   }
}
