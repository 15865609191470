import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as crypto from 'crypto-js';


const helper = new JwtHelperService();
const SeedProduction = 'VendomaticaZnd2018_'

//const decodedToken = helper.decodeToken(token);
//const expirationDate = helper.getTokenExpirationDate(token);
//const isExpired = helper.isTokenExpired(token);

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private router: Router){
  }
  
  public getToken(): string {

    if (localStorage.getItem('userToken')==''||localStorage.getItem('userToken')==null||localStorage.getItem('userToken')==undefined){
      return localStorage.getItem('userToken');
    }else{
      // Decrypt
    let bytes  = crypto.AES.decrypt(localStorage.getItem('userToken').toString(), SeedProduction);
    let decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
    return decryptedData;
    }
    
  };

  public getUsuarioLogeado(): any{

    let bytes  = crypto.AES.decrypt(localStorage.getItem('usuario').toString(), SeedProduction);
    let decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));
    return decryptedData;
  }

  public Logout(){
    localStorage.removeItem('userToken');
    localStorage.removeItem('usuario');
   };

   public LogoutRedirect(){
    localStorage.removeItem('userToken');
    localStorage.removeItem('usuario');
    this.router.navigate(['/authentication/login'])
   };


   // devuelve true si está autenticado
  public isAuthenticated(): boolean {
    // get the token
    const token = this.getToken();
    // return a boolean reflecting 
    // whether or not the token is expired
    if(!helper.isTokenExpired(token))
      return true;
  };

  public addToken(data){
    //console.log(data.token);
    let dataToken = crypto.AES.encrypt(JSON.stringify(data.token), SeedProduction);
    let dataUser =  crypto.AES.encrypt(JSON.stringify(data.data[0]), SeedProduction);
   // console.log(dataToken);

    // Decrypt
    //let bytes  = crypto.AES.decrypt(dataToken.toString(), 'znd');
    //let decryptedData = JSON.parse(bytes.toString(crypto.enc.Utf8));

    //console.log(decryptedData);
    localStorage.setItem('userToken',dataToken);
    localStorage.setItem('usuario', dataUser);
    this.router.navigate(['/Home']);
  }

  public urlApi(): any{
  // return 'http://192.168.254.105:3000';
  // return 'http://localhost:8081';
  // return 'http://isznd.us-east-2.elasticbeanstalk.com';
  return 'https://tarjetas-api.vendomatica.cl'
    //return 'https://tarjetas-api-dev.vendomatica.cl';
  }
}


























/*
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as auth0 from 'auth0-js';

(window as any).global = window;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  auth0 = new auth0.WebAuth({
    clientID: 'pGxmkyERrNWXlQ0n5bx8YXV4OEiM20dc',
    domain: 'znd.auth0.com',
    responseType: 'token id_token',
    audience: 'https://znd.auth0.com/userinfo',
    redirectUri: 'http://localhost:4200/callback',
    scope: 'openid'
  });

  constructor(public router: Router) {}

  public login(): void {
    this.auth0.authorize();
  }

    public handleAuthentication(): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        window.location.hash = '';
        this.setSession(authResult);
        this.router.navigate(['/home']);
      } else if (err) {
        this.router.navigate(['/home']);
        console.log(err);
      }
    });
  }

  private setSession(authResult): void {
    // Set the time that the Access Token will expire at
    const expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime());
    localStorage.setItem('access_token', authResult.accessToken);
    localStorage.setItem('id_token', authResult.idToken);
    localStorage.setItem('expires_at', expiresAt);
  }

  public logout(): void {
    // Remove tokens and expiry time from localStorage
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    // Go back to the home route
    this.router.navigate(['/']);
  }

  public isAuthenticated(): boolean {
    // Check whether the current time is past the
    // Access Token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('expires_at') || '{}');
    return new Date().getTime() < expiresAt;
  }

}*/